import React, { useEffect, useState } from "react";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElementProps,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { getPaymentIntent } from "services/stripe";
import { useWorkflow } from "pages/workflow";
import Button from "components/Button";
import { useRouter } from "next/router";
import { StripeCardNumberElementChangeEvent } from "@stripe/stripe-js";
import classes from "styles/components/Workflow/ReviewPay.module.scss";
import { Checked, Unchecked } from "assets";
import { useModal } from "contexts/modal";
import TnC from "components/TnC";
import Genie from "components/Genie";
import { event } from "utils/gtag";
import Meta from "components/Meta";

export default function ReviewPay() {
  const { openModal } = useModal();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [agree, setAgree] = useState(false);
  const [sendUpdates, setSendUpdates] = useState(false);
  const {
    total,
    orderId,
    values,
    products,
    order,
    redirectStep,
  } = useWorkflow();

  useEffect(() => {
    event({ action: "workflow_step_5_view" });
  }, []);

  const { push, pathname } = useRouter();
  const [brand, setBrand] = useState<
    StripeCardNumberElementChangeEvent["brand"]
  >("unknown");
  const brandLogos: {
    [brand in StripeCardNumberElementChangeEvent["brand"]]: string;
  } = {
    visa:
      "http://www.credit-card-logos.com/images/visa_credit-card-logos/new_visa_medium.gif",

    amex:
      "http://www.credit-card-logos.com/images/american_express_credit-card-logos/american_express_logo_1.gif",
    diners: "",
    discover:
      "http://www.credit-card-logos.com/images/discover_credit-card-logos/discover_network1.jpg",
    jcb: "",
    mastercard:
      "http://www.credit-card-logos.com/images/mastercard_credit-card-logos/mastercard_logo_6.gif",
    unionpay: "",
    unknown: "",
  };

  useEffect(() => {
    if (isNaN(redirectStep)) return;
    push({ pathname, query: { step: redirectStep } });
  }, [redirectStep]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements || loading) {
      return;
    }

    setLoading(true);
    let paymentIntent;

    try {
      const res = await getPaymentIntent({
        price: total * 100,
        coupon_code: order.coupon_code,
        if_coupon: order.coupon_code ? "true" : "false",
      });
      paymentIntent = res.body.intent;
    } catch (error) {
      setLoading(false);
      toast.error("There was an error processing your payment.");
      return;
    }

    if (!paymentIntent) {
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) {
      setLoading(false);
      return;
    }

    const result = await stripe.confirmCardPayment(paymentIntent, {
      payment_method: {
        card: cardElement,
      },
    });

    if (result.error) {
      toast.error("There was an error processing your payment.");
    } else if (result.paymentIntent.status === "succeeded") {
      event({
        action: "purchase",
      });
      toast.success("Payment Successful!");
      push({ pathname, query: { order_id: orderId, success: true } });
    }

    setLoading(false);
  };
  const options: CardNumberElementProps["options"] = {
    classes: {
      base: classes.input,
    },
  };

  return (
    <>
      <Meta tags={{ title: "Checkout" }} />
      <div className={classes.container}>
        <Genie
          prompts={[
            {
              title: "Woohoo! You are ready to get started.",
              description:
                "Enter your payment information below and I will get started on your comprehensive name availability search and start processing your business filing.",
            },
          ]}
        />
        <div className={classes.float}>
          <div className={classes.options}>
            <h3>Select payment method</h3>
            <p className={classes.option}>Credit/Debit card</p>
          </div>
          <form className={classes.payment} onSubmit={handleSubmit}>
            <div className={classes.flex}>
              <div className={classes.inputContainer}>
                <label>
                  Card Number<span>*</span>
                </label>
                <CardNumberElement
                  options={options}
                  onChange={({ complete, brand }) => setBrand(brand)}
                />
              </div>
              <div className={classes.brand}>
                <img src={brandLogos[brand]} alt="" />
              </div>
            </div>
            <div className={classes.flex}>
              <div className={classes.inputContainer}>
                <label>
                  Expiration Date<span>*</span>
                </label>
                <CardExpiryElement options={options} />
              </div>
              <div className={classes.inputContainer}>
                <label>
                  Verification Code<span>*</span>
                </label>
                <CardCvcElement options={options} />
              </div>
            </div>
            <div className={classes.check}>
              <span onClick={() => setAgree((p) => !p)}>
                {agree ? <Checked /> : <Unchecked />}
              </span>
              <p>
                I agree to the{" "}
                <span
                  onClick={() =>
                    openModal(() => <Terms agree={agree} setAgree={setAgree} />)
                  }
                >
                  terms and conditions
                </span>
              </p>
            </div>
            <div
              onClick={() => setSendUpdates((p) => !p)}
              className={classes.check}
            >
              {sendUpdates ? <Checked /> : <Unchecked />}
              <p>Send me marketing updates from IncDecentral</p>
            </div>

            {/* <CardElement /> */}
            <div style={{ height: 30 }}></div>
            <div className={classes.buttons}>
              <Button
                onClick={() => {
                  const product = products.find(
                    (p) => p.ID === values?.product
                  );
                  const isPro = product.product_name === "Ready to Grow";
                  push({ pathname, query: { step: isPro ? 3 : 4 } });
                }}
                type="button"
                variant="outlined"
              >
                Go Back
              </Button>
              <Button
                className={classes.button}
                theme="secondary"
                disabled={!agree || loading}
              >
                {loading ? "Processing..." : `Pay $${total}`}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

const Terms = ({ agree, setAgree }) => {
  const { closeModal } = useModal();

  const [a, sa] = useState(agree);
  useEffect(() => {
    setAgree(a);
  }, [a]);

  return (
    <div className={classes.terms}>
      <h3>Terms and conditions Agreement</h3>
      <div className={classes.text}>
        <TnC />
      </div>
      <div onClick={() => sa((p) => !p)} className={classes.check}>
        {a ? <Checked /> : <Unchecked />}
        <p> I agree to the terms and conditions</p>
      </div>
      <Button disabled={!a} onClick={closeModal} theme="secondary">
        Continue
      </Button>
    </div>
  );
};
