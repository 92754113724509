import Button from "components/Button";
import useNav from "hooks/useNav";
import { useRouter } from "next/router";
import React from "react";
import classes from "styles/components/FormInput.module.scss";
import { event } from "utils/gtag";

interface Props {
  next?: () => void;
  prev?: ((p: number) => number | void) | number;
}

export default function WorkflowButtons({ next, prev }: Props) {
  const { toStep } = useNav();
  const {
    query: { step },
  } = useRouter();

  return (
    <div className={classes.buttons}>
      <Button
        type="button"
        onClick={toStep(
          prev !== null && prev != undefined ? prev : parseInt(step?.toString())
        )}
        variant="outlined"
      >
        Go Back
      </Button>
      {next ? (
        <Button onClick={next} theme="secondary">
          Next Step
        </Button>
      ) : (
        <Button
          type={"submit"}
          theme="secondary"
          onClick={() => event({ action: "add_to_cart" })}
        >
          Next Step
        </Button>
      )}
    </div>
  );
}
